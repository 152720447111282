@mixin create-mq($breakpoint) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (min-width: $breakpoint * 1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $settings) {
  @include create-mq(map-get($settings, 'breakpoint')) {
    $grid-cols: map-get($settings, 'cols');
    $grid-spacing: map-get($settings, 'space');
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: calc(#{calc(100 / calc($grid-cols / $i) ) * 1%} - #{$grid-spacing * 2});
        margin: 0 $grid-spacing;
      }
    }
  }
}

@mixin create-offset-classes($modifier, $settings) {
  @include create-mq(map-get($settings, 'breakpoint')) {
    .col#{$modifier}-offset-0 {
      margin-left: 0;
    }

    $grid-cols: map-get($settings, 'cols');
    $grid-spacing: map-get($settings, 'space');
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-offset-#{$i} {
        margin-left: calc(100 / (calc($grid-cols / $i))) * 1%;
      }
    }
  }
}

$default-grid-spacing: 8px;
$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$map-grid-props: (
  '': (
    'breakpoint': 0,
    'cols': 4,
    'space': $default-grid-spacing
  ),
  '-sm': (
    'breakpoint': $grid__bp-sm,
    'cols': 4,
    'space': $default-grid-spacing
  ),
  '-md':(
    'breakpoint': $grid__bp-md,
    'cols': 8,
    'space': $default-grid-spacing
  ),
  '-lg': (
    'breakpoint': $grid__bp-lg,
    'cols': 12,
    'space': $default-grid-spacing
  )
);

.container {
  max-width: 90%;
  width: 1100px;
  margin: 0 auto;
  z-index: 2;

  &--fluid {
    margin: 0;
    max-width: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$default-grid-spacing * 2});
    margin: 0 -#{$default-grid-spacing};

    @each $modifier, $breakpoint in $map-grid-props {
      @include create-col-classes($modifier, $breakpoint);
      @include create-offset-classes($modifier, $breakpoint);
    }
  }
}
