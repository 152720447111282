@import 'node_modules/@angular/material/theming';

// breakpoints
$xsmall-min: 0;
$xsmall-max: 599px;
$small-min: $xsmall-max + 1;
$small-max: 1023px;
$medium-min: $small-max + 1;
$medium-max: 1439px;
$large-min: $medium-max + 1;
$large-max: 1919px;
$xlarge-min: $large-max + 1;

$default-orange: #f4a716;
$default-anthracite: #272727;

$kiku-orange: (
  50: lighten($default-orange, 90%),
  100: #ffd851,
  200: lighten($default-orange, 60%),
  300: lighten($default-orange, 40%),
  400: lighten($default-orange, 20%),
  500: $default-orange,
  600: darken($default-orange, 20%),
  700: #bc7800,
  800: darken($default-orange, 60%),
  900: darken($default-orange, 80%),
  A100: #ffd851,
  A200: lighten($default-orange, 60%),
  A400: lighten($default-orange, 20%),
  A700: #bc7800,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$kiku-anthracite: (
  50: lighten($default-anthracite, 90%),
  100: #4f4f4f,
  200: lighten($default-anthracite, 60%),
  300: lighten($default-anthracite, 40%),
  400: lighten($default-anthracite, 20%),
  500: $default-anthracite,
  600: darken($default-anthracite, 20%),
  700: #000,
  800: darken($default-anthracite, 60%),
  900: darken($default-anthracite, 80%),
  A100: #4f4f4f,
  A200: lighten($default-anthracite, 60%),
  A400: lighten($default-anthracite, 20%),
  A700: #000,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

@include mat-core();

$primary: mat-palette($kiku-orange);
$accent: mat-palette($kiku-anthracite);

$candy-app-theme: mat-light-theme($primary, $accent);

@include angular-material-theme($candy-app-theme);
