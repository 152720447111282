@import 'styles/fonts';
@import 'styles/theme';
@import 'styles/grid';
@import 'node_modules/@material/layout-grid/mdc-layout-grid';
@import "~bootstrap/dist/css/bootstrap.css";

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

.mat-placeholder-required {
  color: inherit !important;
}

.hide-from-medium {
  @media (min-width: $medium-min) {
    display: none;
  }
}

.clearfix {
  @extend %clearfix;
}

.mat-card-header .mat-card-header-text {
  margin: 0;
}

mat-card-header.filter-container-header.mat-card-header .mat-card-header-text {
  margin: auto;
}

mat-card {
  &.centered-title {
    .mat-card-header-text {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.full-width-form {
  mat-form-field {
    width: 100%;
  }
}

.page-wrap {
  mat-card-header {
    margin-bottom: 16px;
  }
}

.mat-error {
  color: #980E06;
}

.mat-raised-button {
  mat-icon {
    margin-right: 8px;
  }
}

.unauthorized {
  .footer-wrap {
    position: absolute;
    bottom: 0;
  }

  header:empty {
    display: none;
  }
}

.mat-simple-snackbar-action {
  .mat-button {
    .mat-button-wrapper {
      color: mat-color($primary);
    }
  }
}

// temp
.menu-card.user .mat-card .mat-card-actions {
  margin-bottom: 0;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $default-orange;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $default-orange;
}

.mat-radio-outer-circle {
  border-width: 1.5px;
  border-color: rgba(0, 0, 0, 0.1);
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

button {
  &:focus {
    outline: 0;
  }
}

.icon-arrow-back {
  display: inline-block;
  margin-right: 5px;
}

.icon-delete {
  display: inline-block;
  margin-right: 5px;
}

.icon-edit {
  display: inline-block;
}

.icon-add {
  display: inline-block;
}

.icon-add-s {
  margin-right: 7px;
}

.icon-local-shipping--green {
  svg {
    fill: #3CB371;
  }
}

.icon-remove--white {
  svg {
    color: #fff;
    fill: #fff;
  }
}

.icon-exit-to-app {
  display: inline-block;
  margin-left: 5px;

  svg {
    color: #fff;
    fill: #fff;
  }
}

.icon-remove {
  display: inline-block;
  margin-top: -2px;
}