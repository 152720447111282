@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 700;
}
